import { FC, useEffect, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import useScrollTop from 'app/hooks/useScrollTop';
import { FormContainer } from 'app/hooks/useGlobalForm';
import ProductCard from './ProductCard';

const MobileDisplay: FC<PropsWithChildren> = () => {
  const { products, setCurrentProductIndex } = FormContainer.useContainer();

  useEffect(() => {
    setCurrentProductIndex(0);
  }, []);

  useScrollTop(true);

  if (!products?.length) return null;

  return (
    <Box pb={4} width="100%">
      {products.length &&
        products.map((_, i) => {
          return <ProductCard key={i} index={i} />;
        })}
    </Box>
  );
};

export default MobileDisplay;
